@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300;1,400;1,500&display=swap');

* {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
}
body{
  display: flex;
  justify-content: center;
  align-items: center;
  background: gray;
}

.container{
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 256px;
  text-align: center;
  border: 10px solid #101116;
  background: #101116;
  border-radius: 5px;
}
.keypad {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(60px, auto);
}
input[type="text"]{
  height: 75px;
  width: 249px;
  background-color: #10111600;
  border: 0;
  color: #ffff;
  text-align: right;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 1px;
}
#clear {
  grid-column: 1/3;
  grid-row: 1;
  color: #262834;
}

#result{
  grid-column: 3/5;
  grid-row: 5;
  color: #262834;
}
#backspace{
  color: #262834;
}

button {
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: #262834;
  color: #ffff;
  font-weight: 500;
}

input:focus, input:active,
button:focus, button:active{
  outline: none;
}

.highlight{
  background: #56cbdb;
  color: #262834;
}